<template>
  <!--- Campos de busqueda -->
  <div class="app-container">
    <el-row class="margin-bottom-20" justify="center">
      <el-col :lg="18" :md="16" :sm="16" :xs="12">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="KeyName, Nombre" v-model="searchForm.query"
          v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
       <el-col style="display: flex; justify-content: flex-start;" :md="4" :sm="8" :xs="10">
        <el-button
          @click.stop.prevent="handleSearchFilter"
          class="margin-left-10"
          size="mini"
          type="primary"
          icon="el-icon-search"
        >Buscar</el-button>
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleClean"
          size="mini"
          type="warning"
          icon="el-icon-delete"
        >Limpiar Filtros</el-button>
      </el-col>
    </el-row>
       <!---- Select Estatus ------->
    <el-row justify="center">
      <el-col style="display: flex; justify-content: center;" :lg="24" :md="24" :sm="24" :xs="24">
        <div style="text-align: center;" class="grid-content bg-purple">
          <span class=" margin-filter">Registros por fecha</span>
          <el-date-picker
            v-model="filterDateRegisterRange"
            v-on:change="handleFilterDateRegister()"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="Fecha inicial"
            end-placeholder="Fecha final"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :lg="4" :md="4" :sm="4" :xs="24" style="display: flex; justify-content: center;">
        <el-button @click="createLocation" type="success" icon="el-icon-circle-plus-outline" size="mini">Añadir Sucursal</el-button>
      </el-col>
      <!---- boton export -->
      <el-col :lg="4" :md="4" :sm="4" :xs="24" style="display: flex; justify-content: center;">
        <el-button @click="handleExportData" type="success" icon="el-icon-download" size="mini">Exportar</el-button>
      </el-col>
      <!---- Paginacion -->
      <el-col :lg="20" :md="20" :sm="20" :xs="24" style="display: flex; justify-content: flex-end;">
        <el-pagination style="margin-top: 0.5em;" @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
      </el-col>
    </el-row>
    <!---- Tabla de Sucursales ----->
    <el-table size="mini" v-loading="loading" :data="list" element-loading-text="Loading" height="600" fit border
      highlight-current-row style="width: 100%">
      <el-table-column label="KeyName" fixed max-width="160" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.key !== undefined ? scope.row.key : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Nombre" min-width="180" max-width="250" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.name !== undefined ? scope.row.name : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Metadata" type="expand" width="110" align="center">
        <template slot-scope="scope">
          <!-- Tabla ID FamilyJR -->
          <el-table stripe :data="scope.row.metadataKeyValue" size="mini" border highlight-current-row>
            <el-table-column label="Propiedad" align="center">
              <template slot-scope="meta">
                <span style="font-weight: 800;">{{ meta.row.keyObj !== undefined ? meta.row.keyObj : 'N/D' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Valor" align="center">
              <template slot-scope="meta">
                <span>{{ meta.row.valueObj !== undefined ? meta.row.valueObj : 'N/D' }}</span>
              </template>
            </el-table-column>
          </el-table>
          <!-- Fin tabla ID FamilyJR -->
        </template>
      </el-table-column>
      <el-table-column label="Fecha de Registro" width="180" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.created_at !==undefined">{{ scope.row.created_at | formatDate }}</span>
            <span v-else>{{ 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Operaciones" width="410" align="center" fixed="right">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="Editar Sucursal" placement="bottom">
            <el-button @click="handleEditLocation(scope.row._id)" type="warning" size="mini" icon="el-icon-edit" circle></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="Eliminar Sucursal" placement="bottom">
            <el-button @click="handleDeleteLocation(scope.$index, scope.row)" type="danger" size="mini" icon="el-icon-delete" circle></el-button>
          </el-tooltip>
            <el-button @click="handleCreateTerminal(scope.row.key)" type="success" size="mini">
              <span>Agregar Terminal</span>
              <i class="el-icon-mobile icon--bold descriptive-icon"></i>
              <i class="el-icon-plus icon--bold"></i>
            </el-button>
            <el-button @click="handleShowTerminals(scope.row.key)" type="primary" size="mini">
              <span>Ver Terminales</span>
              <i class="el-icon-mobile icon--bold descriptive-icon"></i>
              <i class="el-icon-right icon--bold"></i>
            </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--- Paginacion inferior -->
    <el-row type="flex" justify="end">
      <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
        :total="parseInt(totalPages)">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import { search, getPage, deleteLocation, exportExcel } from '@/api/bioLocations.js'
export default {
  name: 'bioLocations',
  data () {
    return {
      searchForm: {
        query: '',
        location: '',
        type: '',
        initDate: '',
        endDate: ''
      },
      totalPages: '',
      currentPage: '',
      list: null,
      loading: false,
      sizePerPage: 25,
      locations: null,
      types: null,
      filterDateRegisterRange: '',
      pickerOptions: {
        shortcuts: [{
          text: 'Semana pasada',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Mes pasado',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '9 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 270)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Año atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  mounted () {
    this.handleSearchFilter()
  },
  methods: {
    handleClean () {
      this.filterDateRegisterRange = []
      this.searchForm = {
        query: '',
        location: '',
        type: '',
        initDate: '',
        endDate: ''
      }
      this.handleSearchFilter()
    },
    async handleSearchFilter () {
      this.loading = true
      await search(this.searchForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
          this.list.forEach(bioObject => {
            bioObject.metadataKeyValue = []
            for (const key in bioObject.metadata) {
              bioObject.metadataKeyValue.push({
                keyObj: key,
                valueObj: bioObject.metadata[key]
              })
            }
          })
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      this.searchForm.paginate = val
      await getPage(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
          this.list.forEach(bioObject => {
            bioObject.metadataKeyValue = []
            for (const key in bioObject.metadata) {
              bioObject.metadataKeyValue.push({
                keyObj: key,
                valueObj: bioObject.metadata[key]
              })
            }
          })
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
          this.list.forEach(bioObject => {
            bioObject.metadataKeyValue = []
            for (const key in bioObject.metadata) {
              bioObject.metadataKeyValue.push({
                keyObj: key,
                valueObj: bioObject.metadata[key]
              })
            }
          })
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleFilterDateRegister () {
      if (this.filterDateRegisterRange) {
        this.searchForm.initDate = this.filterDateRegisterRange[0]
        this.searchForm.endDate = this.filterDateRegisterRange[1]
        this.handleSearchFilter()
      } else {
        this.searchForm.initDate = ''
        this.searchForm.endDate = ''
        this.handleSearchFilter()
      }
    },
    handleShowTerminals (param) {
      // TODO: Button to show Terminals view
      console.log('send param = ' + param)
      this.$router.push({
        name: 'locations.terminals',
        query: {
          params: param
        }
      })
    },
    createLocation () {
      this.$router.push({ name: 'locations.create' })
    },
    handleEditLocation (idLocation) {
      this.$router.push({
        path: '/locations/edit/',
        query: {
          id: idLocation
        }
      })
    },
    handleDeleteLocation (index, row) {
      this.$confirm(`La Sucursal ${row.name}, sus Terminales y sus Licencias relacionadas serán eliminadas. ¿Desea continuar?`, 'Atención!', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          await deleteLocation(row._id)
            .then(response => {
              this.$message({
                showClose: true,
                message: '¡Se eliminó correctamente!',
                type: 'success'
              })

              this.list.splice(index, 1)
            })
            .catch(this.responseCatch)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Operacion cancelada'
          })
        })
        .finally(() => (this.loading = false))
    },
    handleCreateTerminal (bioLocation) {
      this.$router.push(
        {
          path: '/terminals/create',
          query: {
            location: bioLocation
          }
        })
    },
    async handleExportData () {
      console.log('antes de export')
      this.loading = true
      await exportExcel(
        this.searchForm
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        const name = 'bio_location' + new Date() + '.xlsx'
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        console.log('Archivo Exportado')
      })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang="css">
.icon--bold{
  font-weight: 800;
}

.descriptive-icon{
  margin-left: 0.3em;
  margin-right: 0.3em;
}
</style>
